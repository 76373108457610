import React, {
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { paramMessages } from "./paramMessages";
import { Crud } from "../utils/crud";

export const LanguageContext = createContext(undefined);

const crud = new Crud('language');

// const langs_db = [
//   {
//     "id": 1,
//     "name": "English",
//     "code": "en",
//     "image": "https://cdn.jsdelivr.net/npm/flag-icon-css/flags/1x1/us.svg"
//   },
//   {
//     "id": 2,
//     "name": "Русский",
//     "code": "ru",
//     "image": "https://cdn.jsdelivr.net/npm/flag-icon-css/flags/1x1/ru.svg"
//   },
//   {
//     "id": 3,
//     "name": "Тоҷикӣ",
//     "code": "tj",
//     "image": "https://cdn.jsdelivr.net/npm/flag-icon-css/flags/1x1/tj.svg"
//   }
// ]

const langs_db = (await crud.get({ page: 1 })).data ?? [{
  "id": 1,
  "name": "English",
  "code": "en",
  "image": "https://cdn.jsdelivr.net/npm/flag-icon-css/flags/1x1/us.svg"
}];

export function langByCode(lang_code) {
  return langs_db.filter(x => x.code == lang_code)[0]
}
export function langById(lang_id) {
  return langs_db.filter(x => x.id == lang_id)[0]
}


export const LanguageContextProvider = ({ children }) => {
  let LSLangCode = localStorage.getItem('i18nextLng')
  if (!LSLangCode) {
    localStorage.setItem('i18nextLng', 'en')
    LSLangCode = localStorage.getItem('i18nextLng')
  }
  const [language, setLanguage] = useState(null);
  const [lang_db, setLangDb] = useState(langByCode(LSLangCode))
  const { t, i18n } = useTranslation();
  const getParamMessage = (message_type, variant) => {
    return t(paramMessages[message_type].message(variant, paramMessages[message_type].variants, message_type))
  }

  useEffect(() => {
    const currlang = LSLangCode;
    if (currlang) {
      setLanguage(currlang);
    }
  }, []);
  useEffect(() => {
    setLangDb(langByCode(language) || null)
  }, [language])

  useEffect(() => {
    if (language) i18n.changeLanguage(language);
  }, [language, i18n]);

  const languages = langs_db.reduce((acc, curr) => (acc[curr.code] = { nativeName: curr.name, image: curr.image }, acc), {});

  const onClickLanguageChange = (e, newValue) => {
    const selectedLanguage = newValue || "en";
    setLanguage(selectedLanguage);
    localStorage.setItem("i18nextLng", selectedLanguage);
  };

  return (
    <LanguageContext.Provider
      value={{ t, i18n, onClickLanguageChange, languages, langs_db, lang_db, getParamMessage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => useContext(LanguageContext);